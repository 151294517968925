import store from "@/store";

import { Answer } from "@/types/store.d";

export const packageAnswers = (answers: Record<string, Answer>): string => {
  let formattedAnswer = "";
  for (const index in answers) {
    const answer = answers[index];

    formattedAnswer += `<p><b>Q: ${answer.question}</b></p>`;

    if (Array.isArray(answer.answer)) {
      formattedAnswer += `<p>A: ${answer.answer.join("\r\n")}</p>`;
    } else {
      formattedAnswer += `<p>A: ${answer.answer}</p>`;
    }

    formattedAnswer += "<br/><br/>";
  }

  return encodeURI(formattedAnswer);
};

export const completeEvaluation = (recommendationsUrl: string): void => {
  if (!recommendationsUrl) {
    alert(
      "An error has occurred: Recommendations Url cannot be undefined. Please contact our support for help."
    );
    throw Error("recommendations url cannot be undefined or empty");
  }
  store.commit("endQuiz");
  window.location.href = recommendationsUrl;
};
