
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import Loading from "../../components/Structure/Loading.vue";
import { useStore } from "vuex";
import { checkoutDomain } from "@/config/config";
import { completeEvaluation, packageAnswers } from "@/utils/recommend";

import { Answer } from "@/types/store.d";
import { EMERGENCY_CONTRACEPTION_URL_BASE } from "../../router/constants";

export default defineComponent({
  components: {
    Container,
    Loading,
  },
  setup() {
    const store = useStore();
    const answers: Record<string, Answer> = store.state.answers;
    const packagedAnswers = packageAnswers(answers);

    setTimeout(() => {
      completeEvaluation(
        `${checkoutDomain}/evaluation/${EMERGENCY_CONTRACEPTION_URL_BASE}/end?evaluation=${packagedAnswers}`
      );
    }, 3000);

    return {
      answers,
    };
  },
});
